@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Source Sans Pro", serif;
}
body * {
  @apply transition-colors duration-100;
}

@layer base {
  .dark-mode {
    background-color: #2d2d2d;
    color: white;
    @apply transition-colors duration-100;
  }

  h1 {
    @apply text-3xl my-2;
  }
  h2 {
    @apply text-2xl my-2;
  }
  h3 {
    @apply text-xl my-1 font-medium;
  }
  h4 {
    @apply text-lg my-1 font-semibold;
  }
  h5 {
    @apply text-base my-1 font-semibold;
  }
  h6 {
    @apply text-sm my-1 font-bold;
  }
  p {
    @apply text-base my-1;
  }
  a {
    @apply text-indigo-700;
  }
  a:hover {
    @apply text-indigo-800 underline;
  }
  /* Lists */
  ul {
    @apply list-inside pl-4;
  }
  ul li {
    @apply list-disc;
  }
  ol {
    @apply list-inside pl-4;
  }
  ol li {
    @apply list-decimal;
  }
  ol li > * {
    @apply inline-block;
  }
  /* Form */
  .form-box form {
    padding: 2px;
    box-shadow: 0 0 8px 0 rgba(29, 78, 216);
  }
  /* Table */
  table {
  }
  table > thead,
  table > tbody {
    @apply p-1;
  }
  table > thead {
    @apply border-b-2 border-gray-200;
  }
  table > thead > tr > th,
  table > tbody > tr > td {
    @apply text-left px-3 py-1;
  }
  table th + th,
  table td + td {
    @apply border-l border-gray-200;
  }
  table > tbody > tr {
    @apply border-b border-gray-100;
  }
  table > tbody > tr:last-child {
    @apply border-0;
  }
  table > tbody > tr:nth-child(odd) {
    @apply bg-gray-50 dark:bg-zinc-800;
  }
  table > tbody > tr:nth-child(odd) {
    @apply bg-gray-50 dark:bg-gray-900;
  }
  table > tbody > tr:hover {
    @apply bg-indigo-50 dark:bg-gray-700;
  }
  table > tbody > tr.selected {
    @apply bg-green-300;
  }
  table > tbody > tr > td.selected {
    @apply bg-green-300;
  }
  table > tbody > tr.primary,
  table > tbody > tr.blue {
    @apply bg-blue-600 text-white
    hover:bg-blue-700 hover:text-white
    focus:ring-blue-500
    dark:bg-blue-600;
  }
  table > tbody > tr.secondary,
  table > tbody > tr.indigo {
    @apply bg-indigo-600 text-white
    hover:bg-indigo-700 hover:text-white
    focus:ring-indigo-500
    dark:bg-indigo-600;
  }
  table > tbody > tr.tertiary,
  table > tbody > tr.purple {
    @apply bg-purple-600 text-white
    hover:bg-purple-700 hover:text-white
    dark:bg-purple-600
    focus:ring-purple-500;
  }
  table > tbody > tr.directive,
  table > tbody > tr.success,
  table > tbody > tr.green {
    @apply bg-green-500 text-white
    hover:bg-green-600 hover:text-white
    dark:bg-green-500
    focus:ring-green-400;
  }
  table > tbody > tr.danger,
  table > tbody > tr.red {
    @apply bg-red-600 text-white dark:bg-red-700 dark:hover:bg-red-600
    hover:bg-red-700 hover:text-white
    focus:ring-red-500;
  }
  table > tbody > tr.warning,
  table > tbody > tr.yellow {
    @apply bg-yellow-300 dark:bg-yellow-600 dark:hover:bg-yellow-500 text-white
    hover:bg-yellow-400 hover:text-white
    focus:ring-yellow-300;
  }
  table > tbody > tr.dangerous {
    @apply bg-red-700 dark:bg-red-700 dark:hover:bg-red-800 text-white
    hover:bg-red-800 hover:text-white
    focus:ring-red-600;
  }

  .z-10 {
    z-index: 10;
  }
  /* Buttons */
  .button {
    @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-50 text-base font-medium text-blue-700
    hover:bg-gray-100 hover:text-blue-700 hover:no-underline
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
    sm:w-auto sm:text-sm;
  }
  .button.-primary,
  .button.-blue {
    @apply bg-blue-600 text-white
    hover:bg-blue-700 hover:text-white
    focus:ring-blue-500;
  }
  .button.-secondary,
  .button.-indigo {
    @apply bg-indigo-600 text-white
    hover:bg-indigo-700 hover:text-white
    focus:ring-indigo-500;
  }
  .button.-tertiary,
  .button.-purple {
    @apply bg-purple-600 text-white
    hover:bg-purple-700 hover:text-white
    focus:ring-purple-500;
  }
  .button.-directive,
  .button.-success,
  .button.-green {
    @apply bg-green-500 text-white
    hover:bg-green-600 hover:text-white
    focus:ring-green-400;
  }
  .button.-warning {
    @apply bg-amber-600 text-white
    hover:bg-amber-700 hover:text-white
    focus:ring-amber-500;
  }
  .button.-danger,
  .button.-red {
    @apply bg-red-600 text-white
    hover:bg-red-700 hover:text-white
    focus:ring-red-500;
  }
  .button.-dangerous {
    @apply bg-red-700 text-white
    hover:bg-red-800 hover:text-white
    focus:ring-red-600;
  }
  /* Pagination buttons */
  .-toggle {
    @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white
    hover:bg-gray-50
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
    sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
    disabled:cursor-not-allowed disabled:opacity-50;
  }
  .-pagination {
    @apply bg-white border-gray-300 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium
    hover:bg-gray-50;
  }
  .-pagination.-current {
    @apply bg-indigo-50 border-indigo-500 text-indigo-600 text-sm
    disabled:cursor-not-allowed;
  }
  .-pagination.-gable {
    @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500
    hover:bg-gray-50
    disabled:cursor-not-allowed disabled:opacity-50;
  }
  .-pagination.-gable.-end {
    @apply rounded-l-none rounded-r-md;
  }
  /* Fields (and label) */
  .field + .field {
    @apply mt-3;
  }
  .field + .button {
    @apply mt-3;
  }
  .field.-choice {
    @apply flex items-center;
  }
  .field.-choice + .field.-choice {
    @apply mt-1.5;
  }
  .field label {
    @apply block text-sm font-medium text-gray-700;
  }
  .field p {
    @apply mt-1 mb-0 text-sm text-gray-500 dark:text-white;
  }
  .field select {
    @apply mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm
    focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
    sm:text-sm;
  }
  .field input,
  .field textarea {
    @apply mt-1 block w-full shadow-sm border-gray-300 rounded-md appearance-none px-2 py-2 bg-white border
    focus:ring-indigo-500 focus:border-indigo-500
    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300
    sm:text-sm;
  }
  .field textarea {
    @apply resize-y;
  }
  .field input[type="checkbox"],
  .field input[type="radio"] {
    @apply h-4 w-4 text-indigo-600 border-gray-300 rounded mt-0
    checked:bg-blue-600 checked:border-transparent
    bg-contain bg-center bg-no-repeat
    focus:ring-indigo-500;
  }
  .field.-choice input[type="checkbox"] + span,
  .field.-choice input[type="radio"] + span {
    @apply ml-2;
  }
  .field input[type="radio"] {
    @apply rounded-full;
  }
  .field select {
    @apply w-auto;
  }

  .field input[type="checkbox"]:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
  }
  .field input[type="radio"]:checked {
    background-size: 90%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>');
  }

  .field.-group {
    @apply flex rounded-md shadow-sm;
  }
  .field.-group input {
    @apply rounded-none;
  }
  .field.-group span {
    @apply inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm;
  }

  .field.-group.-l input {
    @apply rounded-l-md;
  }
  .field.-group.-l span {
    @apply rounded-r-md;
  }

  .field.-group.-c input {
  }
  .field.-group.-l span:first-of-type {
    @apply rounded-l-md;
  }
  .field.-group.-l span:last-of-type {
    @apply rounded-r-md;
  }

  .field.-group.-r input {
    @apply rounded-r-md;
  }
  .field.-group.-r span {
    @apply rounded-l-md;
  }
  .field-success input,
  .field-success textarea {
    box-shadow: inset 0 0 4px 0 rgba(16, 185, 129);
  }
  .field-warning input,
  .field-warning textarea {
    box-shadow: inset 0 0 4px 0 #d3b54b;
  }
  .field-error input,
  .field-error textarea {
    box-shadow: inset 0 0 4px 0 #ce4e4e;
  }

  /* Icon */
  .icon {
    @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-50 text-gray-600
    sm:mx-0 sm:h-10 sm:w-10;
  }
  .icon > svg {
    @apply h-6 w-6;
  }
  .icon:empty {
    @apply hidden;
  }
  .icon.-primary,
  .icon.-blue {
    @apply bg-blue-100 text-blue-600;
  }
  .icon.-secondary,
  .icon.-indigo {
    @apply bg-indigo-100 text-indigo-600;
  }
  .icon.-tertiary,
  .icon.-purple {
    @apply bg-purple-100 text-purple-600;
  }
  .icon.-directive,
  .icon.-success,
  .icon.-green {
    @apply bg-green-100 text-green-600;
  }
  .icon.-danger,
  .icon.-red {
    @apply bg-red-100 text-red-600;
  }
  .icon.-dangerous {
    @apply bg-red-200 text-red-700;
  }
  /* Other elements */
  hr {
    @apply my-5;
  }
  blockquote {
    @apply m-3 mx-1 p-3 border-l-4 border-gray-200;
  }
  code {
    @apply font-mono text-red-700 bg-red-50 px-1 bg-opacity-50 rounded;
  }
  pre > code {
    @apply font-mono text-black bg-gray-50 p-4 bg-opacity-50 border block rounded;
  }
  /* For multiple buttons inline (side by side) */
  .button + .button {
    @apply ml-3;
  }
  /* Odd styles */
  .black-overlay {
    box-shadow: 0 0 100vw 100vw rgba(0, 0, 0, 0.1);
  }
  .fullscreen-loading {
    background-size: 200% auto;
    background: repeating-linear-gradient(
        to right,
        #3b82f6 0%,
        #34d399 33%,
        #fbbf24 66%,
        #3b82f6 100%
      )
      0 100%;
    animation: gradient 3s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    min-height: 3px;
  }
  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .border-primary {
    border-color: #f64747;
  }
  .react-calendar__tile--disabled {
    background: #f64747 !important;
    color: white;
  }
}
