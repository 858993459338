@keyframes nav-toggle-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.nav-toggled {
  animation: nav-toggle-rotate 0.1s forwards;
}

@keyframes nav-untoggle-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-untoggled {
  animation: nav-untoggle-rotate 0.1s forwards;
}
